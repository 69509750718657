import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import CheckYourDetailsPage from 'components/CheckDetails';
import { DocumentsDetails } from 'components/CheckDetails/DocumentsSection/validation';
import { KeepInformedDetails } from 'components/CheckDetails/KeepingInformedSection/validation';
import { ContactDetails } from 'components/CheckDetails/YourDriversSection/PolicyHolder/validation';
import { DeclarationDetails } from 'components/Declaration';
import Layout from 'components/Layout';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { navigatePreservingQuote } from 'helpers/navigation';
import { isQuoteSummaryValid } from 'helpers/quoteSummaryValidation';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import { useQuote } from 'state/quote/quote';
import { CsHero, CsMeta } from 'types/contentStack';
import { PriceBannerWhitespace } from './styles';

const STEP = 6;

type CheckYourDetailsProps = {
  data: {
    csCheckYourDetails: {
      meta: CsMeta;
      hero: CsHero;
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csCheckYourDetails {
      meta {
        meta_title
      }
      hero {
        heading
        subheading
      }
      next_button_text
    }
  }
`;

export type FullPreferenceDetails = ContactDetails &
  DocumentsDetails &
  KeepInformedDetails &
  DeclarationDetails;

const CheckYourDetails: React.FC<CheckYourDetailsProps> = ({
  data: {
    csCheckYourDetails: {
      meta,
      hero: { heading, subheading },
    },
  },
  location,
}) => {
  const quote = useQuote();
  const isValidQuoteForTracking = !!quote && isQuoteSummaryValid(quote);

  useEffect(() => {
    if (quote) {
      if (quote.status !== 'Quote') {
        throw new Error('Returned quote does not have status "Quote"');
      } else if (!isQuoteSummaryValid(quote)) {
        navigatePreservingQuote(quoteAndBuyRoutes.quoteSummary);
      }
    }
  }, [quote]);

  usePageTracking(meta.meta_title, isValidQuoteForTracking);
  useCheckoutTracking(STEP, quote, 'Quote and Buy');

  return (
    <LoadQuoteWrapper location={location}>
      <Layout meta={meta} quoteAndBuyStep={STEP} pageType="quoteAndBuy">
        {quote && (
          <>
            <HeroPlain heading={heading} subhead={subheading || undefined} />
            <CheckYourDetailsPage quote={quote} />
          </>
        )}
      </Layout>
      {/* Need to create a space at the bottom of the page for the price banner so that it does not sit over the footer */}
      <PriceBannerWhitespace />
    </LoadQuoteWrapper>
  );
};

export default CheckYourDetails;
